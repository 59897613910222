"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-dialog", {
    staticClass: "add_dialog",
    attrs: {
      width: "600px",
      title: _vm.dialogTitle,
      visible: _vm.dialogVisible,
      "close-on-click-modal": false
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.dialogVisible = $event;
      }
    }
  }, [_c("el-form", {
    ref: "form",
    attrs: {
      rules: _vm.rules,
      model: _vm.form,
      "label-width": "160px"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "电池类型",
      prop: "battery_type"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入"
    },
    model: {
      value: _vm.form.battery_type,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "battery_type", $$v);
      },
      expression: "form.battery_type"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "保护板类型",
      prop: "brand_type"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "请选择保护板类型"
    },
    model: {
      value: _vm.form.brand_type,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "brand_type", $$v);
      },
      expression: "form.brand_type"
    }
  }, _vm._l(_vm.brandTypeList, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.label,
        value: item.value
      }
    });
  }), 1)], 1), _c("el-form-item", {
    attrs: {
      label: "分类前缀",
      prop: "prefix"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入"
    },
    model: {
      value: _vm.form.prefix,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "prefix", $$v);
      },
      expression: "form.prefix"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "标称电压",
      prop: "voltage"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入"
    },
    model: {
      value: _vm.form.voltage,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "voltage", $$v);
      },
      expression: "form.voltage"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "充电器充电电压"
    }
  }, [_c("el-input-number", {
    attrs: {
      label: ""
    },
    model: {
      value: _vm.form.charge_voltage,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "charge_voltage", $$v);
      },
      expression: "form.charge_voltage"
    }
  }), _c("span", [_vm._v(" (为0则跟随默认配置)")])], 1), _c("el-form-item", {
    attrs: {
      label: "充电器充电电流"
    }
  }, [_c("el-input-number", {
    attrs: {
      label: ""
    },
    model: {
      value: _vm.form.charge_current,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "charge_current", $$v);
      },
      expression: "form.charge_current"
    }
  }), _c("span", [_vm._v(" (为0则跟随默认配置)")])], 1), _c("el-form-item", {
    attrs: {
      label: "电池可用状态电量(%)"
    }
  }, [_c("el-input-number", {
    attrs: {
      label: ""
    },
    model: {
      value: _vm.form.can_use_electric,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "can_use_electric", $$v);
      },
      expression: "form.can_use_electric"
    }
  }), _c("span", [_vm._v(" (为0则跟随默认配置)")])], 1), _c("el-form-item", {
    attrs: {
      label: "充电开始电量(%)"
    }
  }, [_c("el-input-number", {
    attrs: {
      label: ""
    },
    model: {
      value: _vm.form.charge_electric_min,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "charge_electric_min", $$v);
      },
      expression: "form.charge_electric_min"
    }
  }), _c("span", [_vm._v(" (为0则跟随默认配置)")])], 1), _c("el-form-item", {
    attrs: {
      label: "充电停止电量(%)"
    }
  }, [_c("el-input-number", {
    attrs: {
      label: ""
    },
    model: {
      value: _vm.form.charge_electric_max,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "charge_electric_max", $$v);
      },
      expression: "form.charge_electric_max"
    }
  }), _c("span", [_vm._v(" (为0则跟随默认配置)")])], 1), _c("el-divider", [_vm._v("\n                涓流充电设置\n                "), _c("el-button", {
    attrs: {
      size: "mini",
      icon: "el-icon-plus",
      type: "primary"
    },
    on: {
      click: function click($event) {
        return _vm.addTrickle();
      }
    }
  }, [_vm._v("增加")])], 1), _c("div", {
    staticStyle: {
      padding: "0 30px"
    }
  }, _vm._l(_vm.form.trickle, function (item, index) {
    return _c("el-form", {
      ref: "subForm",
      refInFor: true,
      attrs: {
        inline: false,
        size: "mini",
        model: item,
        rules: _vm.subRules,
        "label-width": "180px"
      }
    }, [_c("el-form-item", {
      attrs: {
        label: "电池电量(%)",
        prop: "trickle_charge_electric"
      }
    }, [_c("el-input-number", {
      attrs: {
        "controls-position": "right"
      },
      model: {
        value: item.trickle_charge_electric,
        callback: function callback($$v) {
          _vm.$set(item, "trickle_charge_electric", $$v);
        },
        expression: "item.trickle_charge_electric"
      }
    }), _c("span", [_vm._v(" (为0则忽略此条件) ")]), _c("el-button", {
      attrs: {
        size: "mini",
        type: "danger"
      },
      on: {
        click: function click($event) {
          return _vm.delTrickle(index);
        }
      }
    }, [_vm._v("删除")])], 1), _c("el-form-item", {
      attrs: {
        label: "电池温度",
        prop: "trickle_charge_temp"
      }
    }, [_c("el-input-number", {
      attrs: {
        "controls-position": "right"
      },
      model: {
        value: item.trickle_charge_temp,
        callback: function callback($$v) {
          _vm.$set(item, "trickle_charge_temp", $$v);
        },
        expression: "item.trickle_charge_temp"
      }
    }), _c("span", [_vm._v(" (为0则忽略此条件)")])], 1), _c("el-form-item", {
      attrs: {
        label: "充电电流",
        prop: "trickle_charge_current"
      }
    }, [_c("el-input-number", {
      attrs: {
        "controls-position": "right"
      },
      model: {
        value: item.trickle_charge_current,
        callback: function callback($$v) {
          _vm.$set(item, "trickle_charge_current", $$v);
        },
        expression: "item.trickle_charge_current"
      }
    }), _c("span", [_vm._v(" (为0则停止充电)")])], 1), _c("el-divider")], 1);
  }), 1)], 1), _c("div", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      size: "small"
    },
    on: {
      click: function click($event) {
        _vm.dialogVisible = false;
      }
    }
  }, [_vm._v("取 消")]), _c("el-button", {
    attrs: {
      size: "small",
      type: "primary"
    },
    on: {
      click: _vm.submit
    }
  }, [_vm._v("保 存")])], 1)], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;