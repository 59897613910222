"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _battery = require("@/api/iot/battery");
var _default = {
  props: ['editItem'],
  data: function data() {
    return {
      loading: false,
      dialogVisible: false,
      detailTable: [
        // {
        //     label: '换电柜编号',
        //     value: '2409100001',
        // },
      ],
      depotTable: [],
      voltageTable: []
    };
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      var _this = this;
      if (val) {
        this.$nextTick(function () {
          _this.detailTable = [];
          _this.depotTable = [];
          _this.voltageTable = [];
        });
        this.getDetailsdata();
      } else {}
    }
  },
  mounted: function mounted() {},
  methods: {
    getDetailsdata: function getDetailsdata() {
      var _this2 = this;
      console.log(this.editItem);
      this.loading = true;
      (0, _battery.getDetails)({
        id: this.editItem.id
      }).then(function (res) {
        _this2.loading = false;
        _this2.detailTable = res.base;
        _this2.depotTable = res.times;
        _this2.voltageTable = res.voltage;
        _this2.$emit('getList', true);
      }).catch(function () {
        _this2.loading = false;
      });
    }
  }
};
exports.default = _default;