"use strict";

var _interopRequireDefault = require("D:/www/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getBatteryStatusList = getBatteryStatusList;
exports.getBranchStatusList = getBranchStatusList;
exports.getBrandTypeList = getBrandTypeList;
exports.getDeviceStatusList = getDeviceStatusList;
exports.getEsignSignStatusList = getEsignSignStatusList;
exports.getFaultTypeList = getFaultTypeList;
exports.getFixStatusList = getFixStatusList;
exports.getLockStatusList = getLockStatusList;
exports.getOnlieStatusList = getOnlieStatusList;
exports.getOrderPayStatusList = getOrderPayStatusList;
exports.getTranTypeList = getTranTypeList;
exports.getUserCertStatusList = getUserCertStatusList;
exports.getUserRentFreezeStatusList = getUserRentFreezeStatusList;
exports.getUserRentStatusList = getUserRentStatusList;
var _fetch = _interopRequireDefault(require("@/utils/fetch"));
/**
 * 状态列表
 */

// 获取网点状态列表
function getBranchStatusList(params) {
  return _fetch.default.get("/Supplier/status/getBranchStatusList", params);
}
// 获取设备状态列表
function getDeviceStatusList(params) {
  return _fetch.default.get("/Supplier/status/getDeviceStatusList", params);
}
// 获取在线状态列表
function getOnlieStatusList(params) {
  return _fetch.default.get("/Supplier/status/getOnlieStatusList", params);
}
// 获取电池状态列表
function getBatteryStatusList(params) {
  return _fetch.default.get("/Supplier/status/getBatteryStatusList", params);
}
// 获取电池锁定状态列表
function getLockStatusList(params) {
  return _fetch.default.get("/Supplier/status/getLockStatusList", params);
}
// 获取电池保护板类型列表
function getBrandTypeList(params) {
  return _fetch.default.get("/Supplier/status/getBrandTypeList", params);
}
// 获取订单支付状态列表
function getOrderPayStatusList(params) {
  return _fetch.default.get("/Supplier/status/getOrderPayStatusList", params);
}
// 获取用户租用状态列表
function getUserRentStatusList(params) {
  return _fetch.default.get("/Supplier/status/getUserRentStatusList", params);
}
// 获取用户实名状态列表
function getUserCertStatusList(params) {
  return _fetch.default.get("/Supplier/status/getUserCertStatusList", params);
}
// 获取用户冻结状态列表
function getUserRentFreezeStatusList(params) {
  return _fetch.default.get("/Supplier/status/getUserRentFreezeStatusList", params);
}
// 获取故障类型列表
function getFaultTypeList(params) {
  return _fetch.default.get("/Supplier/status/getFaultTypeList", params);
}
// 获取处理状态状态列表
function getFixStatusList(params) {
  return _fetch.default.get("/Supplier/status/getFixStatusList", params);
}

// 获取易签宝合同签约状态列表
function getEsignSignStatusList(params) {
  return _fetch.default.get("/Supplier/status/getEsignSignStatusList", params);
}

// 获取交易类型列表
function getTranTypeList(params) {
  return _fetch.default.get("/Supplier/status/getTranTypeList", params);
}