"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    attrs: {
      width: "800px",
      title: "认证资料",
      visible: _vm.dialogVisible,
      "close-on-click-modal": false
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.dialogVisible = $event;
      }
    }
  }, [_c("div", {
    staticClass: "flex idcard_wrap"
  }, [_c("div", {
    staticClass: "flex-1"
  }, [_c("p", {
    staticClass: "text-center img_header"
  }, [_vm._v("身份证正面")]), _c("div", {
    staticClass: "img_wrap"
  }, [_c("cropUpload", {
    ref: "cropUpload",
    attrs: {
      "show-type": "photo",
      "init-val": _vm.idcard_front,
      "wrapper-width": "250px",
      "wrapper-height": "150px",
      "photo-width": 250,
      "photo-height": 150
    },
    on: {
      setVal: function setVal($event) {
        return _vm.imgChange($event, "idcard_front");
      }
    }
  })], 1)]), _c("div", {
    staticClass: "flex-1"
  }, [_c("p", {
    staticClass: "text-center img_header"
  }, [_vm._v("身份证反面")]), _c("div", {
    staticClass: "img_wrap"
  }, [_c("cropUpload", {
    ref: "cropUpload",
    attrs: {
      "show-type": "photo",
      "init-val": _vm.idcard_back,
      "wrapper-width": "250px",
      "wrapper-height": "150px",
      "photo-width": 250,
      "photo-height": 150
    },
    on: {
      setVal: function setVal($event) {
        return _vm.imgChange($event, "idcard_back");
      }
    }
  })], 1)])]), _vm.editItem && _vm.editItem.is_cert == 1 ? _c("div", {
    staticClass: "btn-box flex flex-center"
  }, [_c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: function click($event) {
        return _vm.auditUser(true);
      }
    }
  }, [_vm._v("通过审核")]), _c("el-button", {
    attrs: {
      type: "danger"
    },
    on: {
      click: function click($event) {
        return _vm.auditUser();
      }
    }
  }, [_vm._v("拒绝审核")])], 1) : _vm._e(), _c("div", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: function click($event) {
        _vm.dialogVisible = false;
      }
    }
  }, [_vm._v("关闭")])], 1), _vm.isShow ? _c("el-image-viewer", {
    attrs: {
      "on-close": _vm.close,
      "url-list": _vm.image,
      "z-index": 9000
    }
  }) : _vm._e()], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;