"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-dialog", {
    staticClass: "add_dialog",
    attrs: {
      width: "600px",
      title: _vm.dialogTitle,
      visible: _vm.dialogVisible,
      "close-on-click-modal": false
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.dialogVisible = $event;
      }
    }
  }, [_c("el-form", {
    ref: "form",
    attrs: {
      rules: _vm.rules,
      model: _vm.form,
      "label-width": "120px"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "换电柜编号",
      prop: "terminal_no"
    }
  }, [_vm._v("\n                " + _vm._s(_vm.form.terminal_no) + "\n            ")]), _c("el-form-item", {
    attrs: {
      label: "联系人",
      prop: "contact_name"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "联系人"
    },
    model: {
      value: _vm.form.contact_name,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "contact_name", $$v);
      },
      expression: "form.contact_name"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "电话",
      prop: "contact_mobile"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "电话"
    },
    model: {
      value: _vm.form.contact_mobile,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "contact_mobile", $$v);
      },
      expression: "form.contact_mobile"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "电价(元/度)",
      prop: "kwh_price"
    }
  }, [_c("el-input-number", {
    attrs: {
      placeholder: "电价"
    },
    model: {
      value: _vm.form.kwh_price,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "kwh_price", $$v);
      },
      expression: "form.kwh_price"
    }
  })], 1)], 1), _c("div", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      size: "small"
    },
    on: {
      click: function click($event) {
        _vm.dialogVisible = false;
      }
    }
  }, [_vm._v("取 消")]), _c("el-button", {
    attrs: {
      size: "small",
      type: "primary",
      loading: _vm.btnLoading
    },
    on: {
      click: _vm.submit
    }
  }, [_vm._v("保 存")])], 1)], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;