"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-dialog", {
    staticClass: "add_dialog",
    attrs: {
      width: "450px",
      title: "修改租期",
      visible: _vm.dialogVisible,
      "close-on-click-modal": false
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.dialogVisible = $event;
      }
    }
  }, [_c("el-form", {
    ref: "form",
    attrs: {
      rules: _vm.rules,
      model: _vm.form,
      "label-width": "120px"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "失效日期"
    }
  }, [_vm._v("\n                " + _vm._s(_vm.form.end_time) + "\n            ")]), _c("el-form-item", {
    attrs: {
      label: "电池类型",
      prop: "battery_type_id"
    }
  }, [_c("el-select", {
    staticStyle: {
      width: "200px"
    },
    attrs: {
      placeholder: "请选择电池类型"
    },
    model: {
      value: _vm.form.battery_type_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "battery_type_id", $$v);
      },
      expression: "form.battery_type_id"
    }
  }, _vm._l(_vm.batteryTypeList, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.battery_type,
        value: item.id
      }
    });
  }), 1)], 1), _c("el-form-item", {
    attrs: {
      label: "剩余次数(次)",
      prop: "can_use_times"
    }
  }, [_c("el-input-number", {
    attrs: {
      label: ""
    },
    model: {
      value: _vm.form.can_use_times,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "can_use_times", $$v);
      },
      expression: "form.can_use_times"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "增加天数(天)"
    }
  }, [_c("el-input-number", {
    attrs: {
      label: ""
    },
    model: {
      value: _vm.form.add_days,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "add_days", $$v);
      },
      expression: "form.add_days"
    }
  }), _c("span", [_vm._v(" (负数为减少天数)")])], 1), _c("el-form-item", {
    attrs: {
      label: "增加小时(小时)"
    }
  }, [_c("el-input-number", {
    attrs: {
      label: ""
    },
    model: {
      value: _vm.form.add_hours,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "add_hours", $$v);
      },
      expression: "form.add_hours"
    }
  }), _c("span", [_vm._v(" (负数为减少小时)")])], 1)], 1), _c("div", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      size: "small"
    },
    on: {
      click: function click($event) {
        _vm.dialogVisible = false;
      }
    }
  }, [_vm._v("取 消")]), _c("el-button", {
    attrs: {
      size: "small",
      type: "primary"
    },
    on: {
      click: _vm.submit
    }
  }, [_vm._v("保 存")])], 1)], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;